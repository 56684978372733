<template>
  <div class="detail-page">
    <Header />
    <Knowledge :data="materialList" />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Knowledge from './knowledge';
import {fetchMaterial} from "@/api/home";

export default {
  components: {
    Header,
    Knowledge,
  },
  data() {
    return {
      materialList: [],
    }
  },
  activated() {
    this.getMaterial();
  },
  methods: {
    async getMaterial() {
      const res = await fetchMaterial({});
      this.materialList = res.data.slice(0, 3);
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;

}
</style>

